import React from "react"
import { Container } from "react-bootstrap"
import Form from "./form"
import SellingForm from "./sellingForm"
import BackgroundImage from 'gatsby-background-image'

const BigHeader = ({title, description, img, formType}) => {
  const backgroundFluidImageStack = [
    img,
    `linear-gradient(180deg, rgb(255, 255, 255, 0.37) 0%, rgb(255, 255, 255, 0.57) 100%)`
  ].reverse()
  
  return (
      <BackgroundImage
        Tag="section"
        className={`component bigHeader bg-grey pt-5`}
        fluid={backgroundFluidImageStack}
      >
      <Container fluid>
        <div className="row align-items-center">
          <div className="col-12 col-md-8">
            <h1 className="mb-4">{title}</h1>
            <p>{description}</p>
          </div>
          <div className="col-12 col-md-4">
              <div className="formWrapper">
                {formType && formType === 'verkaufen' ?
                  <SellingForm />
                : 
                  <Form iframeHeight="770" />
                }
              </div>
          </div>
        </div>
      </Container>
    </BackgroundImage>
  )
}

export default BigHeader