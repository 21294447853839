import React from "react"
import { Container } from "react-bootstrap"
import Image from "gatsby-image"

const ContentTeaser = ({ title, description, btnLabel, img, additionalClass, category, btnTarget }) => (
  <section className={`component contentTeaser py-5 my-200 ${additionalClass}`}>
    <Container fluid>
      <div className="row justify-content-center">
        <div className="col-md-9">
          <div className="row">
            <div className="col-12 col-md-4">
              <Image fluid={img} alt="Hanseatisches Immobilienkontor Schwolle &amp; Gienke GmbH &amp; Co. KG" />
            </div>
            <div className="col-12 col-md-8">
              <span className="category">{category}</span>
              <h2 className="h3 mt-3 mb-4">{title}</h2>
              <p>{description}</p>
              {btnTarget &&
                <a className="mt-4 d-block" href={btnTarget} target="_blank" rel="noopener noreferrer">{btnLabel}</a>
              }
            </div>
          </div>
        </div>
      </div>
    </Container>
  </section>
)

export default ContentTeaser