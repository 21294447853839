import React, { useState } from "react"
import axios from "axios"

const Form = ({ iframeHeight }) => {
  const [page, setPage] = useState(1);
  const [success, setSuccess] = useState(false);
  const [validated, setValidated] = useState(true);

  const [house, setHouse] = useState('');
  const [view, setView] = useState('');
  const [location, setLocation] = useState('');

  const [year, setYear] = useState(0);
  const [size, setSize] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [parking, setParking] = useState('');

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [street, setStreet] = useState('');
  const [zip, setZip] = useState('');
  const [city, setCity] = useState('');

  const submitForm = (event) => {
    event.preventDefault();

    if (page < 3) {
      if (page === 1) {
        if (house && view && location) {
          setValidated(true)
          setPage(2)
        } else {
          setValidated(false)
        }
      }

      if (page === 2) {
        if (year > 0 && size > 0 && rooms > 0 && parking) {
          setPage(3)
        } else {
          setValidated(false)
        }
      }
    }

    if (page === 3) {
      if (!name || !email || !phone || !street || !zip || !city) {
        setValidated(false)
        return false
      }

      setValidated(true)

      axios({
        method: "post",
        url: '/sendMail.php',
        headers: { "content-type": "application/json" },
        data: {
          "house": house,
          "view": view,
          "location": location,
          "year": year,
          "size": size,
          "rooms": rooms,
          "parking": parking,
          "name": name,
          "email": email,
          "street": street,
          "zip": zip,
          "city": city,
          "phone": phone
        }
      })
        .then(result => {
          if (result.data.sent) {
            window.gtag('event', 'conversion', {'send_to': 'AW-601692041/GZtLCMOk2d8BEImv9J4C'})
            setSuccess(true)
          } else {
            setSuccess(true)
          }
        });
    }
  }

  return (
    <iframe src="/form.html" frameBorder="0" width="100%" height={iframeHeight} border="0"></iframe>
  )

  // return (
  //   <section className="component form">
  //     {/* <div className="pages">Schritt {page} / 3</div> */}
  //     <form id="ajaxForm" onSubmit={submitForm} method="POST">

  //       <div style={{display: (page === 1 && !success) ? 'block' : 'none'}}>
  //         <div className="form-group">
  //           <label style={{color: (!validated && house === '') ? 'red' : null}} htmlFor="house">Art der Immobilie *</label>
  //           <select className="form-control" id="house" onBlur={(event) => setHouse(event.target.value)}>
  //             <option>Bitte auswählen</option>
  //             <option>Wohnung</option>
  //             <option>Einfamilienhaus</option>
  //             <option>Doppelhaushälfte</option>
  //             <option>Rheinhaus</option>
  //             <option>Mehrfamilienhaus</option>
  //           </select>
  //         </div>
  //         <div className="form-group">
  //           <label style={{color: (!validated && view === '') ? 'red' : null}} htmlFor="view">Aussicht *</label>
  //           <select className="form-control" id="view" onBlur={(event) => setView(event.target.value)}>
  //             <option>Bitte auswählen</option>
  //             <option>andere Häuser</option>
  //             <option>Wald</option>
  //             <option>Feld</option>
  //             <option>See/Meer</option>
  //           </select>
  //         </div>
  //         <div className="form-group">
  //           <label style={{color: (!validated && location === '') ? 'red' : null}} htmlFor="location">Lage *</label>
  //           <select className="form-control" id="location" onBlur={(event) => setLocation(event.target.value)}>
  //             <option>Bitte auswählen</option>
  //             <option>ruhig</option>
  //             <option>belebt</option>
  //             <option>ggf. Verkehrslärm oder ähnliches</option>
  //           </select>
  //         </div>
  //         <button type="submit" className="btn">Weiter</button>
  //       </div>

  //       <div style={{display: (page === 2 && !success) ? 'block' : 'none'}}>
  //         <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //           <label style={{color: (!validated && year === 0) ? 'red' : null}} htmlFor="year">Baujahr *</label>
  //           <input type="number" className="form-control" id="year" aria-describedby="Baujahr" placeholder="Baujahr" onBlur={(event) => setYear(event.target.value)} />
  //         </div>
  //         <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //           <label style={{color: (!validated && size === 0) ? 'red' : null}} htmlFor="size">Grundstück in qm *</label>
  //           <input type="number" className="form-control" id="size" aria-describedby="Grundstück in qm" placeholder="Grundstück in qm" onBlur={(event) => setSize(event.target.value)} />
  //         </div>
  //         <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //           <label style={{color: (!validated && rooms === 0) ? 'red' : null}} htmlFor="rooms">Anzahl der Zimmer *</label>
  //           <input type="number" className="form-control" id="rooms" aria-describedby="Anzahl der Zimmer" placeholder="Anzahl der Zimmer" onBlur={(event) => setRooms(event.target.value)} />
  //         </div>
  //         <div className="form-group">
  //           <label style={{color: (!validated && parking === '') ? 'red' : null}} htmlFor="parking">Parkplatz *</label>
  //           <select className="form-control" id="parking" onBlur={(event) => setParking(event.target.value)}>
  //             <option>Bitte auswählen</option>
  //             <option>Carport</option>
  //             <option>im Freien</option>
  //             <option>Garage</option>
  //           </select>
  //         </div>
  //         <button type="submit" className="btn">Weiter</button>
  //       </div>

  //       <div style={{display: (page === 3 && !success) ? 'block' : 'none'}}>
  //           <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //             <label style={{color: (!validated && name === '') ? 'red' : null}} htmlFor="name">Name *</label>
  //             <input type="text" className="form-control" id="name" aria-describedby="Name" placeholder="Name" onBlur={(event) => setName(event.target.value)} />
  //           </div>
  //           <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //             <label style={{color: (!validated && email === '') ? 'red' : null}} htmlFor="email">Email *</label>
  //             <input type="email" className="form-control" id="email" aria-describedby="Email" placeholder="Email" onBlur={(event) => setEmail(event.target.value)} />
  //           </div>
  //           <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //             <label style={{color: (!validated && phone === '') ? 'red' : null}} htmlFor="phone">Telefon *</label>
  //             <input type="tel" className="form-control" id="phone" aria-describedby="Phone" placeholder="Phone" onBlur={(event) => setPhone(event.target.value)} />
  //           </div>
  //           <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //             <label style={{color: (!validated && street === '') ? 'red' : null}} htmlFor="street">Straße und Hausnummer *</label>
  //             <input type="text" className="form-control" id="street" aria-describedby="Straße" placeholder="Straße" onBlur={(event) => setStreet(event.target.value)} />
  //           </div>
  //           <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //             <label style={{color: (!validated && zip === '') ? 'red' : null}} htmlFor="zip">PLZ *</label>
  //             <input type="text" className="form-control" id="zip" aria-describedby="PLZ" placeholder="PLZ" onBlur={(event) => setZip(event.target.value)} />
  //           </div>
  //           <div className="form-group d-flex flex-column align-items-start justify-content-start">
  //             <label style={{color: (!validated && city === '') ? 'red' : null}} htmlFor="city">Stadt *</label>
  //             <input type="text" className="form-control" id="city" aria-describedby="Stadt" placeholder="Stadt" onBlur={(event) => setCity(event.target.value)} />
  //           </div>
  //           <button type="submit" className="btn">Absenden</button>
  //       </div>
  //       {success &&
  //         <div>
  //           <p className="color-blue">Vielen Dank! Ihre Anfrage wurde erfolgreich gesendet.</p>
  //         </div>
  //       }
  //     </form>
  //   </section>
  // )
}

export default Form
